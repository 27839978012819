/**
 * Order is very important.
 * 1st: SCSS variable overrides
 * 2nd: build import
 * 3rd: component hooks
 * 4th: component import
 * 5th: CSS variables declaration/override (build includes :root level declaration)
 * 6th: other custom CSS
 */

// font
//@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Varela%20Round:wght@200;300;400;500;600;700;800&display=swap');

// alternative font: alef
//@import url('https://fonts.googleapis.com/css2?family=Alef:wght@200;300;400;500;600;700;800&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// 1st: SCSS variable overrides
$global-prefix : '';


// 2nd: build import
@import '../../../../../../otherSrc/client/we/scss/build';

// our custom component // * NOTE: we can not include partial SCSS files inside the client forlder, atm.

// 3rd: component hooks


@mixin hook-button {
    &--facebook {
        @include _button-color(var(--c-facebook), var(--c-bg), var(--c-bg), var(--c-facebook), true);
        border: 2px solid var(--c-facebook);
    }
}

// 4th: component import
@import '../../../../../../otherSrc/client/we/scss/components';



// 5th: CSS variables declaration/override
:root {
    --c-core-lead-1           : #5D5D90;
    --c-core-lead-2           : #443F70;
    --c-core-lead             : #1E184F;

    --c-core-alt-2            : #FF7388;// ;#75C594;
    --c-core-alt              : #5A0365;// ;#5CAC7B; // this is darker than the design


  //  --c-core-inverse          : #1E184F;
  //  --c-core-bg               : #ffffff;
  //  --c-core-bg-4             : #F2F2F2;
 //   --c-text                  : #2a2a2a; // design uses purple and gray
    --c-comp-lead             : var(--c-success);
    --c-comp-lead-2           : #5CAC7B;
    --c-comp-alt              : var(--c-warning); // this is the original color. it has poor contrast
    --c-comp-alt-2            : #CAAD00;

  //  --c-gray-1                : #EAEAE9;
   // --c-gray-2                : #DADAD9;
  //  --c-gray                  : #CBCBCA;
   // --c-gray-4                : #989897;
   // --c-gray-5                : #656564;
   // --c-success               : #5CAC7B;
   // --c-success-inverse       : var(--c-bg);
   // --c-warning               : #C0A300; // darkened compt alt, not in scheme
   // --c-warning-inverse       : var(--c-bg);
   // --c-danger                : #E9596E;
   // --c-danger-inverse        : var(--c-bg);
   // --c-error                 : var(--c-danger);
   // --c-error-inverse         : var(--c-danger-inverse);
}

// 6th: other custom CSS
*,
*::before,
*::after {
    box-sizing: border-box;

}

// 6th: custom css
html, body  {
    background: var(--c-bg);

    &[lang=he] * {
        font-family: 'Rubik', 'Assistant', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    }

}
